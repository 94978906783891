<template>

	<Head title="Welcome" />

	<v-row class="justify-center">
		<v-col cols="12" class="pa-0">
			<div class="min-h-[80vh] align-content-center welcome-header">
				<span>
					<h1
						class="text-center text-6xl font-bold drop-shadow-[1px_1px_2px_black] text-transparent bg-clip-text bg-gradient-to-t from-orange-800/50 via-amber-100/80 to-transparent">
						Welcome to Ageare
					</h1>
					<h1
						class="text-center text-6xl font-bold drop-shadow-[1px_1px_2px_black] text-transparent bg-clip-text bg-gradient-to-t from-orange-800/50 via-transparent to-transparent reflection">
						Welcome to Ageare
					</h1>
				</span>
				<div class="backdrop-blur backdrop-opacity-90 bg-gray-700/60 shadow-2xl -mt-10">
					<p class="text-center typing-text text-ellipsis text-nowrap skew-x-12">
						<span id="typed" class="font-mono"></span>
					</p>
				</div>
			</div>
		</v-col>
	</v-row>

</template>

<script setup>
import AppLayout from '@/Layouts/Guest.vue'
import { onMounted, ref } from 'vue'
import Typed from 'typed.js'

defineOptions({
	layout: AppLayout,
	name: 'Title',
})

const props = defineProps({
	//name
})

const introText = ref([
	'Manage multiple properties.',
	'Rent collection for all your units.',
	'Add charges on top of rent.',
])

function mounted() {
	new Typed('#typed', {
		strings: introText.value,
		typeSpeed: 50,
		shuffle: false,
		loop: true,
		startDelay: 200,
		smartBackspace: false,
		// cursorChar: '_',
		backDelay: 2000,
		backSpeed: 10,
	})
}

onMounted(() => {
	mounted()
})

</script>

<style scoped>
.welcome-header {
	background-image: linear-gradient(to top right, rgba(255, 0, 0, 0.225), rgba(0, 45, 128, 0.716)), url('https://picsum.photos/2000/800');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
}
.typing-text {
	font-size: 1.5rem;
	background-image: linear-gradient(rgba(181, 179, 179, 0.206), rgba(186, 198, 50, 0.532)), url('https://picsum.photos/2000/300/?blur');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	background-clip: text;
	color: transparent;
}

.reflection {
	transform: rotatex(180deg) translatey(6px);
}

</style>
